const doctorInfoStore = {
    state: {
        empId: '',
        mcdpCd: ''
    },
    mutations: {
        getEmpId: function (state, empId) {
            state.empId = empId;
        },
        getMcdpCd: function (state, mcdpCd) {
            state.mcdpCd = mcdpCd;
        }
    }
}

export default doctorInfoStore