import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import { isLoginCheck, isNotLoginCheck } from './loginGuard'

const routes = [
    // main page
    {
        path: '/kr/index.do',
        name: 'krMain',
        component: () => {
            return import('../views/main/kr/MainView.vue');
        },
        children: [{
            path: '/',
            component: () => {
                return import('../views/main/kr/MainView.vue');
            }
        }],
        // main page path가 없으면 '/kr/index.do'로 호출
        beforeEnter: (to, from, next) => {
            if (window.location.pathname == '/') {
                window.location.pathname = '/kr/index.do'
            } else {
                return next();
            }
        }
    },
    // main page ENG
    {
        path: '/en/index.do',
        name: 'enMain',
        component: () => {
            return import('../views/main/en/EnMainView.vue');
        },
    },

    // 통합
    // board, contents
    {
        path: '/:lang/:path1/:path2.do',
        name: 'kumc',
        component: () => import('../views/merge/MergeView.vue'),
        children: [{
            path: '/:lang/:path1/:path2/:path3.do',
            component: () => import('../views/merge/MergeView.vue')
        }],
        alias: [
            '/:lang/:path1/:path2/:path3/:path4.do',
        ]
    },
    // 게시판 detail
    {
        path: '/:lang/:path1/view.do',
        name: 'boardDetail',
        component: () => import('../views/board/BoardDetailView.vue')
    },
    //언론보도
    {
        path: '/:lang/notice-news/press-report.do',
        name: 'report',
        component: () => import('../views/board/ReportBoardView.vue')
    },

    // footer service
    // 인터넷이용약관
    {
        path: '/:lang/service/rull-internet.do',
        name: 'rull-internet',
        component: () => import('../views/service/RullInternetView.vue'),
    },
    // 개인정보처리방침
    {
        path: '/:lang/service/privacy.do',
        name: 'privacy',
        component: () => import('../views/service/PrivacyView.vue'),
    },
    // 환자권리장전
    {
        path: '/:lang/service/patient-right.do',
        name: 'patient-right',
        component: () => import('../views/service/PatientRightView.vue'),
    },
    // 이메일주소 무단수집 거절
    {
        path: '/:lang/service/refusal-collection.do',
        name: 'refusal-collection',
        component: () => import('../views/service/RefusalCollectionView.vue'),
    },

    // 고영캠퍼스
    // 층별안내
    {
        path: '/:lang/introduction/koyoung/floor.do',
        name: 'koyoung-floor',
        component: () => import('../views/koyoung/KoyoungFloorView.vue'),
    },
    // 회의실현황
    {
        path: '/:lang/introduction/koyoung/meetingRoom.do',
        name: 'koyoung-meeting-room',
        component: () => import('../views/koyoung/MeetingRoomView.vue'),
    },
    // 공지사항
    {
        path: '/:lang/B054/list.do',
        name: 'koyoung-notice',
        component: () => import('../views/koyoung/KoyoungBoardView.vue'),
    },
    // 공지사항 상세페이지
    {
        path: '/:lang/B054/view.do',
        name: 'koyoung-notice-detail',
        component: () => import('../views/koyoung/KoyoungBoardDetailView.vue'),
    },
    // 자주 묻는 질문
    {
        path: '/:lang/koyoung-faq/list.do',
        name: 'koyoung-faq',
        component: () => import('../views/koyoung/KoyoungFaqView.vue'),
        alias: [
            '/:lang/en-koyoung-faq/list.do'
        ]
    },
    // 층별안내
    {
        path: '/:lang/introduction/koyoung/location.do',
        name: 'koyoung-location',
        component: () => import('../views/koyoung/KoyoungLocationView.vue'),
    },

    // inner 클래스 밖으로 컨텐츠 내용 넣는 컴포넌트(소개, 연혁, 메디사이언스 파크)
    {
        path: '/:lang/introduction/mediSciencePark_1.do',
        name: 'inner_out_ms',
        component: () => import('../views/intro/InnerOutView.vue'),
    },
    {
        path: '/:lang/introduction/introduction.do',
        name: 'inner_out_in',
        component: () => import('../views/intro/InnerOutView.vue'),
    },
    {
        path: '/:lang/introduction/history.do',
        name: 'inner_out_hi',
        component: () => import('../views/intro/InnerOutView.vue'),
    },
    // 현황(fullpage)
    {
        path: '/:lang/introduction/status.do',
        name: 'status',
        component: () => import('../views/intro/StatusView.vue')
    },

    // 호랑이 가게
    // 제품소개
    {
        path: '/:lang/B048/list.do',
        name: 'horangigagae-intro',
        component: () => import('../views/horangigagae/HorangIntroView.vue'),
    },
    // 제품소개 상세페이지
    {
        path: '/:lang/B048/view.do',
        name: 'horangigagae-intro-detail',
        component: () => import('../views/horangigagae/HorangIntroDetailView.vue'),
    },
    // 자주묻는 질문
    {
        path: '/:lang/horangigagae-faq/list.do',
        name: 'horangigagae-faq',
        component: () => import('../views/horangigagae/HorangFaqView.vue'),
    },
    // 공지사항
    {
        path: '/:lang/B049/list.do',
        name: 'horangigagae-notice',
        component: () => import('../views/horangigagae/HorangNoticeView.vue'),
    },
    // 공지사항 상세페이지
    {
        path: '/:lang/B049/view.do',
        name: 'horangigagae-notice-detail',
        component: () => import('../views/horangigagae/HorangNoticeDetailView.vue'),
    },

    // 통합검색
    {
        path: '/:lang/search/list.do',
        name: 'total-search',
        component: () => import('../views/search/SearchView.vue'),
    },

    //부서템플릿
    //정보전달
    {
        path: '/kr/department/department/infoTrans/:path1.do',
        name: 'dept_infoTrans',
        component: () => import('../views/department/infoTrans/infomationTransferView.vue'),
        alias: [
            '/kr/department/center/infoTrans/:path1.do',
            '/kr/department/cancerTeam/infoTrans/:path1.do',
            '/kr/department/cancerClinic/infoTrans/:path1.do',
            '/kr/department/admin/infoTrans/:path1.do',
        ]
    },
    //퀵메뉴
    {
        path: '/kr/department/department/quickMenu/:path1.do',
        name: 'dept_quickMenu',
        component: () => import('../views/department/quickMenu/quickMenuView.vue'),
        alias: [
            '/kr/department/center/quickMenu/:path1.do',
            '/kr/department/cancerTeam/quickMenu/:path1.do',
            '/kr/department/cancerClinic/quickMenu/:path1.do',
            '/kr/department/admin/quickMenu/:path1.do',
        ]
    },
    //홍보
    {
        path: '/kr/department/department/promotion/:path1.do',
        name: 'dept_promotion',
        component: () => import('../views/department/promotion/promotionView.vue'),
        alias: [
            '/kr/department/center/promotion/:path1.do',
            '/kr/department/cancerTeam/promotion/:path1.do',
            '/kr/department/cancerClinic/promotion/:path1.do',
            '/kr/department/admin/promotion/:path1.do',
        ]
    },
    // 콘텐츠
    {
        path: '/kr/department/department/:path1.do',
        name: 'dept_content',
        component: () => import('../views/department/content/deptContentsView.vue'),
        alias: [
            '/kr/department/center/:path1.do',
            '/kr/department/cancerTeam/:path1.do',
            '/kr/department/cancerClinic/:path1.do',
            '/kr/department/admin/:path1.do',
        ]
    },
    // 게시판
    {
        path: '/kr/department/department/:path1/list.do',
        name: 'dept_board',
        component: () => import('../views/department/board/deptBoardView.vue'),
        alias: [
            '/kr/department/center/:path1/list.do',
            '/kr/department/cancerTeam/:path1/list.do',
            '/kr/department/cancerClinic/:path1/list.do',
            '/kr/department/admin/:path1/list.do',
        ]
    },
    // 게시판 상세 페이지
    {
        path: '/kr/department/department/:path1/view.do',
        name: 'dept_boardDetail',
        component: () => import('../views/department/board/deptBoardDetailView.vue'),
        alias: [
            '/kr/department/center/:path1/view.do',
            '/kr/department/cancerTeam/:path1/view.do',
            '/kr/department/cancerClinic/:path1/view.do',
            '/kr/department/admin/:path1/view.do',
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'error',
        component: () => import('../views/error/Error.vue'),
        alias: [
            '/main/index.do',
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
