const registInfoStore = {
    state: {
        memName: '',                        // memName
        memBirthday: '',                    // memBirth
        memGender: '',                      // memGender
        memEmail: '',                       // memEmail
        memHpNo: '',                        // memHpNo
        memId: '',                          // memId
        memType: '',                        // memType
        memCi: '',                          // ci
        memDi: '',                          // di
        memJoinRoute: '',                   // 가입경로
        memYoungAgreeYn: '',                // 14미만 동의 여부
        memProtectorAgreeYn: '',            // 법정대리인 수신동의 여부
        memThirdpartyProvideAgreeYn: '',    // 3자 제공동의 여부
        memFinishId: '',                    // 회원가입 완료창에 띄울 아이디
        memFinishName: '',                  // 회원가입 완료창에 띄울 이름
        memFinishEmail: '',                 // 회원가입 완료창에 띄울 이메일
        type: '',                           // 일반, 어린이, 외국인, sns 구분
        snsType: '',                        // Naver, Kakao 구분
        findChk: '',                     // 아이디찾기 flag
        niceType: '',                     // 인증Type
        ipinType: '',                       // 아이핀 인증 타입
        findMemId: '',                     // 비밀번호찾기 회원 ID
    },
    mutations: {
        getMemName: function (state, memName) {
            state.memName = memName;
        },
        getBirth: function (state, memBirthday) {
            state.memBirthday = memBirthday;
        },
        getGender: function (state, memGender) {
            state.memGender = memGender;
        },
        getEmail: function (state, memEmail) {
            state.memEmail = memEmail;
        },
        getPhone: function (state, memHpNo) {
            state.memHpNo = memHpNo;
        },
        getId: function (state, memId) {
            state.memId = memId;
        },
        getMemType: function (state, memType) {
            state.memType = memType;
        },
        getMemCi: function (state, memCi) {
            state.memCi = memCi;
        },
        getMemDi: function (state, memDi) {
            state.memDi = memDi;
        },
        getJoinRoute: function (state, memJoinRoute) {
            state.memJoinRoute = memJoinRoute;
        },
        getYoungAgree: function (state, memYoungAgreeYn) {
            state.memYoungAgreeYn = memYoungAgreeYn;
        },
        getProtectorAgree: function (state, memProtectorAgreeYn) {
            state.memProtectorAgreeYn = memProtectorAgreeYn;
        },
        getThirdpartyAgree: function (state, memThirdpartyProvideAgreeYn) {
            state.memThirdpartyProvideAgreeYn = memThirdpartyProvideAgreeYn;
        },
        getFinishId: function (state, memFinishId) {
            state.memFinishId = memFinishId;
        },
        getFinishName: function (state, memFinishName) {
            state.memFinishName = memFinishName;
        },
        getFinishEmail: function (state, memFinishEmail) {
            state.memFinishEmail = memFinishEmail;
        },
        getType: function (state, type) {
            state.type = type;
        },
        getSnsType: function (state, snsType) {
            state.snsType = snsType;
        },
        getFindChk: function (state, findChk) {
            state.findChk = findChk;
        },
        getNiceType: function (state, niceType) {
            state.niceType = niceType;
        },
        getIpinType: function (state, ipinType) {
            state.ipinType = ipinType;
        },
        getFindMemId: function (state, findMemId) {
            state.findMemId = findMemId;
        },
    }
}

export default registInfoStore