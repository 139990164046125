import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './assets/resource/js/axios/axiosInterceptor'
import MasonryWall from '@yeger/vue-masonry-wall'
import globalMethods from './assets/resource/js/global/global'

window.Kakao.init("db24157d85ac0bc8fc7e9d12e073e6e8");

document.title = "고려대학교 의료원";

// 병원코드, 다국어, 병원No store
store.commit("getHost", location.hostname.split('.')[0]);
var hpCd = '0';

store.commit("getHpCd", hpCd);
store.commit("getLang", location.pathname.split('/')[1]);
var instNo = '0';

store.commit("getInstNo", instNo);

const app = createApp(App);
app.config.globalProperties.axios = axios;

var sync = 'N';
axios
    .get('/menu.do', {
        params: {
            instNo: store.state.hpInfoStore.instNo,
            lang: store.state.hpInfoStore.lang
        },
        headers: {
            'Cache-Control': 'no-store',
            'Pragma': 'no-store',
            'Expires': '0'
        }
    })
    .then((res) => {
        app.config.globalProperties.menuList = res.data;
        sync = 'Y';
    })
    .catch(err => console.log(err));

var interval = setInterval(function(){
    console.log(sync);
    if(sync == 'Y'){
        app.use(store).use(router).use(MasonryWall).use(globalMethods).mount('#app');
        clearInterval(interval);
    }
},100);
