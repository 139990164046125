<template>
    <div id="wrap">
        <div id="skipNav">
            <a href="#contioner">본문 바로가기</a>
            <a href="#header">글로벌 네비게이션 바로가기</a>
        </div>
        <router-view />
    </div>
</template>

<script>
export default {
    watch: {
        $route(to, from) {
            if (to != from) {
                if (to.name == undefined) {
                    window.scrollTo(0, 0);
                } else {
                    if (!to.name.includes('koyoung')) {
                        window.scrollTo(0, 0);
                    }
                }
            }
        }
    }
}
</script>

<style>
@import '@/assets/resource/css/com/common.css';
@import '@/assets/resource/css/com/common_media.css';
@import '@/assets/resource/css/com/reset.css';
@import '@/assets/resource/css/com/slick.css';
@import '@/assets/resource/css/com/style.css';
@import '@/assets/resource/css/com/style_media.css';
@import '@/assets/resource/css/com/swiper-bundle.css';

@import '@/assets/resource/css/fonts/font.css';
@import '@/assets/resource/css/pages/institution.css';
@import '@/assets/resource/css/pages/institution_media.css';
</style>
