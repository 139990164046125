const boardNoStore = {
    state: {
        boardNo: ''
    },
    mutations: {
        getBoardNo: function (state, boardNo) {
            state.boardNo = boardNo;
        },
    }
}

export default boardNoStore