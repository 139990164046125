const treatmentStore = {
    state: {
        type: 'A'
    },
    mutations: {
        getTreatment: function (state, type) {
            state.type = type;
        }
    }
}

export default treatmentStore