import axios from "axios";
import store from "@/store"

const axiosInstance = axios.create();

// 요청 interceptor
axiosInstance.interceptors.request.use(
    function(config){
        //$('.loding_wrap').show();
        //$('html, body').addClass('scroll_none');
        
        //let timerId = 0;
        //let sum = 0;
        //function lodingTimer(){ // 이 함수는 5초마다 실행됩니다.
        //        sum += 1; // 매 5초마다 숫자 1을 sum 변수에 더합니다.
        //    $('.loding_wrap .loder_number').html(sum);
        //    //console.log(sum)
        //} 
        
        //timerId = setInterval(function() {
        //    lodingTimer() 
        //    }, 100); // 100ms 가 경과하면 이 함수가 실행합니다.
        return config;
    },
    function(error){
        return Promise.reject(error);
    }
)
// 응답 interceptor
axiosInstance.interceptors.response.use(
    function(response){
        //$('.loding_wrap').hide();
        //$('html, body').removeClass('scroll_none');
        return response;
    },
    function(error){
        return Promise.reject(error);
    }
)

export default axiosInstance