import Swiper from 'swiper';

const methods = {
    // kr, str 삭제 api 추가 - /kr/B021/list.do => /api/B021.do
    // board
    changeStr(item, str) {
        var result = '';
        var strResult = '';
        if (item.indexOf(`/${this.$store.state.hpInfoStore.lang}`) == 0) {
            if(item.includes('department/center') || item.includes('department/department')){
                result = item.replace(`/${this.$store.state.hpInfoStore.lang}/department/${item.split('/')[3]}`, '');
                result = result.replace(str, '');
                strResult = '/api/board' + result;
            }else{
                result = item.replace(`/${this.$store.state.hpInfoStore.lang}`, '');
                result = result.replace(str, '');
                strResult = '/api/board' + result;
            }
        } else {
            result = item.replace(str, '');
            strResult = '/api/board' + result;
        }
        return strResult;
    },

    // download func
    fileDownload(attach, name) {
        this.axios({
            url: `/downloadFile.do?attachNo=${attach}`,
            method: 'GET',
            responseType: 'blob'
        })
            .then(res => {
                var fileUrl = window.URL.createObjectURL(new Blob([res.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileUrl;
                fileLink.setAttribute('download', `${name}`);
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            })
    },

    // tab
    tabResize1() {
        //tab
        const tabSwiper01 = new Swiper('.tab_type01.swiper', {
            //pagination: '.swiper-pagination',
            slidesPerView: "auto",
            paginationClickable: true,
            spaceBetween: 0
        });
        //tab 간격
        if ($('.tab_type01').length) {
            let mtarget = $('.tab_type01.swiper .swiper-wrapper .swiper-slide.active');
            let snbwrap = $('.tab_type01.swiper .swiper-wrapper');
            let targetPos = mtarget.position();
            let box = $('.tab_type01.swiper');
            let boxHarf = box.width() / 2;
            let pos;
            let listWidth = 0;
            let listNum = snbwrap.find('.swiper-slide').length;
            $('.tab_type01').attr('style', '--i:' + listNum)
            snbwrap.find('.swiper-slide').each(function () { listWidth += $(this).outerWidth(); })
            let selectTargetPos;
            if (targetPos != undefined) {
                selectTargetPos = targetPos.left + mtarget.outerWidth() / 2;
            }


            if (listWidth > box.outerWidth() == true) {
                $('.tab_type01').addClass('max');
                if (selectTargetPos <= boxHarf) { // left
                    pos = 0;

                } else if ((listWidth - selectTargetPos) <= boxHarf) { //right
                    pos = listWidth - box.width();
                } else {
                    pos = selectTargetPos - boxHarf;
                }


                snbwrap.css({
                    "transform": "translate3d(" + (pos * -1) + "px, 0, 0)",
                    "transition-duration": "500ms"
                })
            } else {
                $('.tab_type01').removeClass('max');
            }
        }
    },

    tabResize2() {
        //tab
        const tabSwiper02 = new Swiper('.tab_type02.swiper', {
            //pagination: '.swiper-pagination',
            slidesPerView: "auto",
            paginationClickable: true,
            spaceBetween: 0
        });
        //tab 간격
        if ($('.tab_type02').length) {
            let mtarget02 = $('.tab_type02.swiper .swiper-wrapper .swiper-slide.active');
            let snbwrap02 = $('.tab_type02.swiper .swiper-wrapper');
            let targetPos02 = mtarget02.position();
            let box02 = $('.tab_type02.swiper');
            let boxHarf02 = box02.width() / 2;
            let pos02;
            let listWidth02 = 0;
            let listNum02 = snbwrap02.find('.swiper-slide').length;
            $('.tab_type02').attr('style', '--i:' + listNum02)
            snbwrap02.find('.swiper-slide').each(function () { listWidth02 += $(this).outerWidth(); })
            let selectTargetPos02;
            if (targetPos02 != undefined) {
                selectTargetPos02 = targetPos02.left + mtarget02.outerWidth() / 2;
            }


            if (listWidth02 > box02.outerWidth() == true) {
                console.log(selectTargetPos02 + '/' + listWidth02);
                $('.tab_type02').addClass('max');
                if (selectTargetPos02 <= boxHarf02) { // left
                    pos02 = 0;

                } else if ((listWidth02 - selectTargetPos02) <= boxHarf02) { //right
                    pos02 = listWidth02 - box02.width();
                } else {
                    pos02 = selectTargetPos02 - boxHarf02;
                }


                snbwrap02.css({
                    //"transform": "translate3d("+ (pos02*-1) +"px, 0, 0)",
                    //"transition-duration": "500ms"
                })
            } else {
                $('.tab_type02').removeClass('max');
            }
        }
    },

    tabGray() {
        let tab_gray = document.querySelector(".tab_content");
        let tabTarget = document.querySelector(".tab_type02");
        if (tab_gray != null && tab_gray.classList.contains('tab_gray')) {
            tabTarget.classList.add("tab_gray");
        } else {
            tabTarget.classList.remove("tab_gray");
        }
    },
    clip() {
        let url = '';
        const textarea = document.createElement("textarea");
        document.body.appendChild(textarea);
        url = window.document.location.href;
        textarea.value = url;
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        alert("해당 페이지 링크가 복사되었습니다.")
    },

    changeInput(input, e) {
        this[input] = e.target.value;
    },

    goMenu(menu) {
        if (menu.menuType == 'L' && menu.menuUrl.indexOf('http') >= 0) {
            if (menu.linkMethod == '_blank') {
                window.open(menu.menuUrl);
            } else {
                location.href = menu.menuUrl;
            }
        } else {
            if (menu.linkMethod == '_blank') {
                window.open(menu.menuUrl);
            } else {
                this.$router.push(menu.menuUrl);
            }
            
        }
    },
    goArticle(article) {
        if (article.linkUrl != '' && article.linkUrl != null) {
            if (article.linkMethod == '_blank') {
                window.open(article.linkUrl);
            } else {
                location.href = article.linkUrl;
            }
        } else {
            if(this.$route.query.deptCd != null){
                this.$router.push(`view.do?article=${article.articleNo}&deptCd=${this.$route.query.deptCd}`);
            }else{
                this.$router.push(`view.do?article=${article.articleNo}`);
            }
        }

    },
    dateToStr(d,dot){
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const date = d.getDate();

        return `${year}`+dot+`${month >= 10 ? month : '0' + month}`+dot+`${date >= 10 ? date : '0' + date}`
    },
    getInstName(instNo){
        const lang = this.$store.state.hpInfoStore.lang;
        var result = '';

        if(lang == 'kr'){
            switch(instNo){
                case 0:
                    result = '의료원';
                    break;
                case 1:
                    result = '안암';
                    break;
                case 2:
                    result = '구로';
                    break;
                case 3:
                    result = '안산';
                    break;
            }
        }else{
            switch(instNo){
                case 0:
                    result = 'KUMC';
                    break;
                case 1:
                    result = 'ANAM';
                    break;
                case 2:
                    result = 'GURO';
                    break;
                case 3:
                    result = 'ANSAN';
                    break;
            }
        }

        return result;
    },
    isKr(){
        if(this.$store.state.hpInfoStore.lang == 'kr'){
            return true;
        }else{
            return false;
        }
    }
}

export default {
    install(Vue) {
        Vue.config.globalProperties.$changeStr = methods.changeStr;
        Vue.config.globalProperties.$fileDownload = methods.fileDownload;
        Vue.config.globalProperties.$tabResize1 = methods.tabResize1;
        Vue.config.globalProperties.$tabResize2 = methods.tabResize2;
        Vue.config.globalProperties.$tabGray = methods.tabGray;
        Vue.config.globalProperties.$clip = methods.clip;
        Vue.config.globalProperties.$changeInput = methods.changeInput;
        Vue.config.globalProperties.$goMenu = methods.goMenu;
        Vue.config.globalProperties.$goArticle = methods.goArticle;
        
        Vue.config.globalProperties.$dateToStr = methods.dateToStr;
        Vue.config.globalProperties.$getInstName = methods.getInstName;
        Vue.config.globalProperties.$isKr = methods.isKr;
    }
}