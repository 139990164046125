import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import hpInfoStore from './hpInfoStore'
import doctorInfoStore from './doctorInfoStore'
import treatmentStore from './treatmentStore'
import boardNoStore from './boardNoStore'
import registInfoStore from './registInfoStore'
import loginTypeStore from './loginTypeStore'

export default createStore({
    plugins: [
        createPersistedState({
            paths: ['hpInfoStore', 'doctorInfoStore', 'treatmentStore', 'boardNoStore', 'registInfoStore', 'loginTypeStore']
        })
    ],
    state: {
        kumcApi: {
            searchApi: { // 통합검색
                search: '/api/search/search.do', // 통합검색
                popword: '/api/search/popword.do',    //인기검색어
                recommend: '/api/search/recommend.do', // 연관검색
            },
            articleApi: { // 게시판 컨트롤러
                article: '/api/article/', // 게시글 조회, 등록, 삭제 // 상세조회
                boardInfo: '/api/board/',    //게시판정보
                newsList: '/api/article/newsList' // 언론보도 조회
            },
            deptHpApi: { // 부서 홈페이지
                deptCd: '/api/dept/deptCd.do', // deptCd 없을 경우 deptCd 조회
                hpMenu: '/api/dept/hpMenu.do', // 메뉴 관리
                hpSetting: '/api/dept/hpSetting.do', // 메인 게시판
            },
            koyoungApi:{
                room: '/api/koyoung/room.do'
            },
            departmentApi: { // 부서 컨트롤러
                department: '/api/department.do', // 부서 목록 조회
                departmentInfo: '/api/department/info.do', // 부서 조회
                departmentLocation: '/api/department/location.do', // 위치 안내
                quick: '/api/department/quick.do' // 퀵메뉴
            },
            appointmentApi: { // 예약 컨트롤러
                getDepartment: '/api/getDepartmentList.do', // 예약 진료과
                doctorList: '/api/getDoctorList.do', // 예약 의료진목록 조회
                doctorTime: '/api/getDoctorTime.do', // 진료 가능 시간표
                nameCheck: '/api/reservation/nameCheck.do', // 실명인증
                getPatInfo: '/api/reservation/getPatInfo.do', // 환자정보조회
                reservation: '/api/reservation/reserve.do', // 예약 목록, 취소, 입력
                recentReserveInfo: '/api/reservation/recentReserveInfo.do', // 최근진료내역
                simpleReservation: '/api/reservation/simpleReservation.do', // 간편예약, 첫방문 간편예약
            },
            doctorApi: { // 의료진 컨트롤러
                doctorCons: '/api/doctor/consonantList.do', // 초성
                doctorList: '/api/doctorApi', // 의료진 목록
                record: '/api/getDoctorRecordList.do', // 의료진 이력
                schedule: '/api/getDoctorSchedule.do', // 의료진 진료일정
                thesis: '/api/getDoctorThesisList.do', // 의료진 논문
                thesisEmr: '/api/doctor/getDoctorThesisScholarList.do' // 의료진 논문
            },
            registApi: { // 회원가입 컨트롤러
                encData: '/api/user/api/getEncData.do', // 본인인증 encData
                encDataChk: '/api/user/api/phone/result.do', // 본인인증 확인 결과
                ipinEncData: '/api/user/api/getIpinEncData.do', // 아이핀 encData
                ipinEncDataChk: '/api/user/api/ipin/result.do', // 아이핀 확인 결과
                parentChk: '/api/user/api/member/parentCheck.do', // 보호자 확인
                checkId: '/api/user/checkDuplicate.do', // ID 중복체크
                findId: '/api/user/findId.do', // ID 찾기
                findPassword: '/api/user/findPassword.do', // 비밀번호 찾기
                nation: '/api/user/nationList.do', // 국가코드 조회
                registerReg: '/api/user/registerReg.do', // 회원가입
                checkDuplicateDi: '/api/user/checkDuplicateDi.do', // 중복 가입 체크
                pwChange: '/api/user/pwChange.do', // 비밀번호 변경
                updateMember: '/api/user/updateMember.do', // 회원정보 수정
            },
            loginApi: { // 로그인 컨트롤러
                login: '/member/login.do', // 로그인
                logout: '/member/logout.do', // 로그아웃
            },
            myPageApi: {
                myInfo: '/api/info.do', // 회원정보
            },
            favoApi: {
                favoAdd: '/api/favoInfo/favoInfoAddData.do' // 즐겨찾기 등록
            },
            nonpaymentApi: { // 비급여 진료비용
                list: '/api/nonpayment/nonpaymentList.do', // 비급여진료비용 조회
            },
        }
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        /**
         * 병원 domain / 병원 넘버 코드 / 병원 코드 / 다국어 타입
         */        
        hpInfoStore: hpInfoStore,        
        // 의사 정보
        doctorInfoStore: doctorInfoStore,
        // 진료과, 센터, 클리닉 타입
        treatmentStore: treatmentStore,
        // boardNo
        boardNoStore: boardNoStore,
        // 회원가입 정보
        registInfoStore: registInfoStore,
        // 로그인 시 부여될 type
        loginTypeStore: loginTypeStore,
    }
})
