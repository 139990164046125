import store from "@/store";

const loginTypeStore = {
    state: {
        status: false,
        flag: false // 로그인 flag
    },
    mutations: {
        getLoginStatus: function (state, status) {
            state.status = status;
        },
        getLoginFlag: function (state, flag) {
            state.flag = flag;
        }
    },
    actions: {
        // 로그인 이후 일정 시간 지나면 status 값 변경
        setLoginStatus({commit}, status){
            if(store.state.loginTypeStore.status == true){
                commit('getLoginStatus', status);
                console.log('false -> true')
                setTimeout(() => {
                    commit('getLoginStatus', false);
                    console.log('true -> false')
                }, 1790000)
            }
        }
    }
}

export default loginTypeStore