const hpInfoStore = {
    state: {
        host: '',
        hpCd: '',
        instNo: '',
        lang: ''
    },
    mutations: {
        getHost: function (state, host) {
            state.host = host;
        },
        getHpCd: function (state, hpCd) {
            state.hpCd = hpCd;
        },
        getInstNo: function (state, instNo) {
            state.instNo = instNo;
        },
        getLang: function (state, lang) {
            state.lang = lang;
        }
    }
}

export default hpInfoStore